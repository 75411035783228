.container {
  display: flex;
  max-width: 2000px;
  margin: 0 auto;
}

.svg-container {
  position: relative;
  height: calc(100vh - 70px);
  aspect-ratio: 1440/1024;
  overflow: hidden;
  .svg {
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    .floor:hover,
    .floor.active {
      fill-opacity: 0.6;
      filter: blur(2px);
    }

    .floor {
      fill: rgb(255, 255, 255);

      transition: 0.1s;
    }
    @media (max-width:500px) {
      translate: 12%;
      
    }
  }
}

.floor-info {
  padding: 50px 30px;
  width: 30%;
  min-width: 200px;
  h1 {
    color: #525252;
    text-align: center;
    padding-bottom: 20px;
  }
  h2 {
    padding-bottom: 15px;
  }
}
