@import url('https://fonts.googleapis.com/css2?family=Montaga&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Montaga&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: inherit;
  // outline: 1px solid red;
}

a {
  color: inherit;
  text-decoration: none;
}

body {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
}

.montaga {
  font-family: "Montaga", serif;
  font-weight: 400;
  font-style: normal;
}
