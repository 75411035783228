.container {
  margin: 0 20vw;
  padding: 0 0 200px;
}

.svg-container {
  aspect-ratio: 1440 / 923;
  // max-height: 80vh;
  position: relative;
  margin: 0 auto;
  .apartment-info {
    position: absolute;
    width: 40%;
    top: 40%;
    left: 50%;
    translate: -50%;
    h1 {
      font-weight: 500;
      color: rgba(0, 0, 0, 0.25);
      font-size: 6vw;
      text-align: center;
    }
  }
  // .svg {
  //   // max-height: 80vh;
  // }
  .apartment {
    fill-opacity: 0.4;
    &.sold {
      fill: rgb(155, 0, 0);
    }
    &.aviable {
      fill: rgb(2, 100, 2);
    }
    &.not_full_paid,
    &.yellow {
      fill: yellow;
    }
    &.reserved {
      fill: blue;
    }
  }
  .apartment:hover,
  .apartment.active {
    fill-opacity: 1;
    stroke: #0014ff; /* Border color */
    stroke-width: 8; /* Border width */
    mix-blend-mode: color;
  }
}
.floor-container {
  margin-bottom: 10px;
  & > p {
    padding: 10px;
  }
  .floors {
    display: flex;
    gap: 10px;
    padding: 10px;
    flex-wrap: wrap;
  }
}
.info-container {
  padding: 30px 10%;
  h1 {
    font-weight: 500;
    padding-bottom: 40px;
  }
  .statuses {
    display: flex;
    gap: 50px calc(5% + 20px);
    align-items: center;
    flex-wrap: wrap;
  }
  .status {
    gap: 20px;
    display: flex;
    align-items: center;
    h2 {
      font-weight: 500;
      font-size: 20px;
    }

    &.sold {
      color: #d05d5d;
    }
    &.aviable {
      color: #51d05d;
    }

    &.not_full_paid {
      color: #93946a;
    }

    &.reserved {
      color: #517cd0;
    }
  }
}

.content {
  display: flex;
  flex-wrap: wrap;
  gap: 10%;
  h1 {
    font-size: 22px;
    width: 45%;
    color: grey;
    span {
      font-weight: 400;
      color: black;
    }
  }
}

.form {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

@media (max-width: 2000px) {
  .container {
    margin: 0 10vw;
  }
  .svg-container .apartment-info h1 {
    font-size: 7vw;
  }
}
@media (max-width: 1000px) {
  .container {
    margin: 0 0vw;
  }
  .svg-container .apartment-info h1 {
    font-size: 9vw;
  }
}
