.navbar {
  display: flex;
  align-items: center;
  padding: 5px 10%;
gap: 5%;
border-bottom: 1px solid rgba(0, 0, 0, 0.284);
  img{
    height: 100%;
  }
  a.active{
    color: #049C56;
  }
}
