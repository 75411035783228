.container {
  width: 100%;
  height: 100vh;
  background-image: url("../../assets/login.png");
  background-size: cover;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card {
  width: clamp(300px,90vw,400px);
  padding: 40px 20px;
  border-radius: 20px;
  background-color: white;
  img {
    display: block;
    width: 100px;
    margin: 0 auto 30px;
  }
 

 

  
}
